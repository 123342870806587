.subscribeToOur {
  width: 265px;
  position: relative;
  font-weight: 600;
  display: inline-block;
}
.frameChild,
.inputBox {
  height: 48px;
  position: relative;
  border-radius: var(--br-5xs);
}
.inputBox {
  flex: 1;
  background-color: var(--color-whitesmoke);
  min-width: 194px;
}
.frameChild {
  width: 118px;
  background-color: var(--color-cadetblue);
  display: none;
}
.subscribe1 {
  width: 70px;
  position: relative;
  line-height: 150%;
  font-weight: 600;
  display: inline-block;
  min-width: 70px;
  z-index: 1;
}
.rectangleParent {
  border-radius: var(--br-5xs);
  background-color: var(--color-cadetblue);
  flex-direction: row;
  padding: var(--padding-smi) var(--padding-5xl) var(--padding-sm);
}
.footerBranding,
.rectangleParent,
.subscribe,
.subscribeInput {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.subscribeInput {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-base);
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--color-aliceblue);
}
.footerBranding,
.subscribe {
  flex-direction: column;
}
.subscribe {
  align-self: stretch;
  gap: var(--gap-11xl);
}
.footerBranding {
  width: 432px;
  gap: var(--gap-35xl);
  min-width: 432px;
  max-width: 100%;
}
.overview,
.product {
  position: relative;
  display: inline-block;
}
.product {
  font-weight: 600;
  min-width: 77px;
}
.overview {
  min-width: 71px;
}
.features,
.pricing,
.solutions,
.tutorials {
  position: relative;
  display: inline-block;
  min-width: 66px;
}
.pricing,
.solutions,
.tutorials {
  min-width: 71px;
}
.pricing,
.tutorials {
  min-width: 67px;
}
.pricing {
  min-width: 53px;
}
.linkColumns,
.productParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.linkColumns {
  gap: var(--gap-2xl);
  font-size: var(--font-size-base);
  font-family: var(--font-abeezee);
}
.productParent {
  gap: var(--gap-11xl);
}
.company {
  position: relative;
  font-weight: 600;
  display: inline-block;
  min-width: 92px;
}
.aboutUs,
.careers,
.mediaKit,
.news,
.press {
  position: relative;
  display: inline-block;
  min-width: 70px;
}
.careers,
.mediaKit,
.news,
.press {
  min-width: 59px;
}
.mediaKit,
.news,
.press {
  min-width: 42px;
}
.mediaKit {
  min-width: 71px;
}
.aboutUsParent,
.companyParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.aboutUsParent {
  gap: var(--gap-2xl);
  font-size: var(--font-size-base);
  font-family: var(--font-abeezee);
}
.companyParent {
  padding: 0 var(--padding-6xs) 0 0;
  gap: var(--gap-11xl);
}
.resources {
  align-self: stretch;
  position: relative;
  font-weight: 600;
}
.blog {
  position: relative;
  display: inline-block;
  min-width: 35px;
}
.events,
.helpCentre,
.newsletter,
.tutorials1 {
  position: relative;
  display: inline-block;
  min-width: 83px;
}
.events,
.helpCentre,
.tutorials1 {
  min-width: 51px;
}
.helpCentre,
.tutorials1 {
  min-width: 91px;
}
.tutorials1 {
  min-width: 67px;
}
.blogParent {
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-2xl);
  font-size: var(--font-size-base);
  font-family: var(--font-abeezee);
}
.blogParent,
.frameParent,
.resourcesParent {
  display: flex;
  align-items: flex-start;
}
.resourcesParent {
  width: 101px;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-11xl);
}
.frameParent {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.footerContent,
.siteLinks {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.siteLinks {
  width: 523px;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-7xs) 0 0;
  box-sizing: border-box;
  min-width: 523px;
}
.footerContent {
  width: 1240px;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.socialIcons {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.privacyPolicy,
.termsCondition {
  position: relative;
  display: inline-block;
  min-width: 122px;
}
.privacyPolicy {
  min-width: 92px;
}
.legalLinks {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.copyright2024All {
  width: 244px;
  position: relative;
  text-align: right;
  display: inline-block;
}
.legal {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.footer4,
.socialLinks {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
}
.socialLinks {
  width: 1240px;
  align-items: flex-start;
  gap: var(--gap-xl);
  font-size: var(--font-size-sm);
  font-family: var(--font-abeezee);
}
.footer4 {
  align-self: stretch;
  background: linear-gradient(
    180deg,
    rgba(0, 4, 6, 0.01),
    rgba(0, 3, 4, 0.64) 22.31%,
    rgba(2, 4, 5, 0.8) 44.63%,
    rgba(4, 5, 5, 0.8) 66.53%,
    #010101
  );
  overflow: hidden;
  align-items: center;
  padding: var(--padding-61xl) var(--padding-xl) var(--padding-9xl);
  box-sizing: border-box;
  gap: var(--gap-69xl);
  z-index: 2;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--color-white);
  font-family: var(--font-sf-pro);
}
@media screen and (max-width: 1050px) {
  .footerBranding,
  .siteLinks {
    flex: 1;
  }
  .footerContent {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media screen and (max-width: 750px) {
  .footerBranding,
  .siteLinks {
    min-width: 100%;
  }
  .legal {
    flex-wrap: wrap;
  }
  .footer4 {
    gap: var(--gap-25xl);
    padding-top: var(--padding-33xl);
    padding-bottom: var(--padding-xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .subscribeToOur {
    font-size: var(--font-size-base);
  }
  .subscribeInput {
    flex-wrap: wrap;
  }
  .footerBranding {
    gap: var(--gap-8xl);
  }
  .company,
  .product,
  .resources {
    font-size: var(--font-size-base);
  }
  .frameParent {
    flex-wrap: wrap;
  }
  .footer4 {
    gap: var(--gap-3xl);
  }
}
