.icon {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  display: none;
  z-index: 0;
}
.backgroundShape {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.createNewAccount,
.parent {
  max-width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.parent {
  flex: 1;
  flex-direction: column;
  position: relative;
  gap: 100px;
  background-image: url(/public/1-5@2x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.createNewAccount {
  width: 100%;
  background-color: var(--color-white);
  overflow: hidden;
  flex-direction: row;
  line-height: normal;
  letter-spacing: normal;
}

@media screen and (max-width: 750px) {
  .parent {
    gap: 50px;
  }
}
@media screen and (max-width: 450px) {
  .parent {
    gap: 25px;
  }
}
