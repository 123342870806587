.stablesailLogo2Icon {
  height: 66px;
  width: 66px;
  position: relative;
  object-fit: cover;
  z-index: 1;
}
.stablesail {
  position: relative;
  letter-spacing: 0.02em;
  font-weight: 500;
  display: inline-block;
  min-width: 105px;
  white-space: nowrap;
  z-index: 1;
}
.brandName,
.stablesailLogo2Parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.brandName {
  flex-direction: column;
  padding: var(--padding-lg) 0 0;
}
.stablesailLogo2Parent {
  flex-direction: row;
  gap: var(--gap-5xs);
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--color-white);
  font-family: var(--font-poppins);
}
