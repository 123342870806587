.headerChild {
  height: 100px;
  width: 1440px;
  position: relative;
  background: linear-gradient(
    180deg,
    rgba(15, 15, 15, 0.3) 9.41%,
    rgba(17, 20, 23, 0.29) 89%,
    rgba(52, 100, 152, 0.18)
  );
  display: none;
  max-width: 100%;
}
.home {
  font-weight: 600;
  min-width: 50px;
}
.scaleUp {
  transition: transform 0.3s ease;
}
.scaleUp:hover {
  transform: scale(1.2);
}
.aboutUs,
.blog,
.home,
.howItWorks {
  position: relative;
  letter-spacing: 0.02em;
  display: inline-block;
  z-index: 1;
}
.howItWorks {
  min-width: 105px;
  white-space: nowrap;
}
.aboutUs,
.blog {
  min-width: 37px;
}
.aboutUs {
  min-width: 74px;
  white-space: nowrap;
}
.navigation,
.navigationLinks {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.navigationLinks {
  width: 355px;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.navigation {
  width: 428px;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 0 var(--padding-lgi);
  box-sizing: border-box;
}
.signIn {
  position: relative;
  text-decoration: underline;
  letter-spacing: 0.02em;
  font-weight: 600;
  display: inline-block;
  min-width: 57px;
  white-space: nowrap;
  z-index: 1;
}
.signInButton {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* padding: var(--padding-4xs) 0 0; */
}
.startForFree {
  position: relative;
  line-height: 150%;
  font-weight: 600;
  display: inline-block;
  min-width: 90px;
}
.signInActions,
.startForFreeWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.startForFreeWrapper {
  border-radius: var(--br-5xs);
  background-color: var(--color-cadetblue);
  overflow: hidden;
  padding: var(--padding-3xs-5) var(--padding-3xs);
  white-space: nowrap;
  z-index: 1;
  text-align: center;
  font-size: var(--font-size-sm);
  font-family: var(--font-inter);
}
.signInActions {
  /* gap: var(--gap-11xl); */
}
.header,
.userActions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-smi);
}
.header {
  align-self: stretch;
  background: linear-gradient(
    180deg,
    rgba(15, 15, 15, 0.3) 9.41%,
    rgba(17, 20, 23, 0.29) 89%,
    rgba(52, 100, 152, 0.18)
  );
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  padding: var(--padding-mid) var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-199xl);
  top: 0;
  z-index: 99;
  position: static;
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-white);
  font-family: var(--font-poppins);
}
@media screen and (max-width: 1200px) {
  .header {
    gap: var(--gap-90xl);
  }
}
@media screen and (max-width: 1050px) {
  .navigationLinks {
    display: none;
  }
  .navigation {
    width: 73px;
  }
}
@media screen and (max-width: 750px) {
  .header {
    gap: var(--gap-35xl);
  }
}
@media screen and (max-width: 450px) {
  .signInActions {
    display: none;
  }
  .header {
    gap: var(--gap-8xl);
  }
}
