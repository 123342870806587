.bodyIcon {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.frameChild {
  align-self: stretch;
  height: 1434px;
  position: relative;
  background-color: var(--color-gray-300);
  display: none;
}
.useOfStablesail {
  margin: 0;
  width: 478.7px;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  flex-shrink: 0;
  text-shadow: 0 9px 17px rgba(0, 0, 0, 0.42);
  max-width: 100%;
  z-index: 2;
}
.useOfStablesailWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  max-width: 100%;
}
.loremIpsumDolor,
.sitEgestasLectus,
.suspendisseAmetDiam,
.urnaOrciPellentesque {
  margin-bottom: 0;
}
.loremIpsumDolorSitAmetCon {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  padding-left: 43px;
}
.loremIpsumDolorContainer {
  margin: 0;
  align-self: stretch;
  height: 250px;
  position: relative;
  font-size: var(--font-size-13xl);
  line-height: 50px;
  font-weight: 400;
  font-family: inherit;
  display: inline-block;
  text-shadow: 0 9px 17px rgba(0, 0, 0, 0.42);
  z-index: 2;
}
.frameParent,
.heroContent {
  display: flex;
  max-width: 100%;
}
.frameParent {
  width: 908px;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 14px;
}
.heroContent {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--font-size-39xl);
  color: var(--color-white);
  font-family: var(--font-nunito);
}
.frameItem {
  width: 1440px;
  height: 338px;
  position: relative;
  background-color: var(--color-darkslategray-400);
  display: none;
  max-width: 100%;
}
.dontMissOut,
.watchOrSkim {
  margin: 0;
}
.startTutorial,
.watchOrSkimContainer {
  position: relative;
  display: inline-block;
}
.watchOrSkimContainer {
  margin: 0;
  font-size: inherit;
  line-height: 54px;
  font-weight: 400;
  font-family: inherit;
  text-shadow: 0 9px 17px rgba(0, 0, 0, 0.42);
  max-width: 100%;
  z-index: 3;
}
.startTutorial {
  line-height: 150%;
  font-weight: 600;
  min-width: 102px;
}
.startTutorialWrapper,
.tutorialButton {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.startTutorialWrapper {
  border-radius: var(--br-5xs);
  background-color: var(--color-cadetblue-100);
  overflow: hidden;
  justify-content: flex-start;
  padding: var(--padding-base) var(--padding-31xl);
  white-space: nowrap;
  z-index: 3;
}
.tutorialButton {
  width: 1240px;
  justify-content: center;
  max-width: 100%;
  font-size: var(--font-size-base);
  font-family: var(--font-inter);
}
.rectangleGroup {
  flex: 1;
  background-color: var(--color-darkslategray-400);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-21xl) var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-21xl);
  max-width: 100%;
  z-index: 2;
}
.cta,
.onboarding,
.rectangleParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.cta {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 var(--padding-xl);
  box-sizing: border-box;
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-13xl);
  color: var(--color-white);
  font-family: var(--font-nunito);
}
.onboarding,
.rectangleParent {
  flex-direction: column;
}
.rectangleParent {
  align-self: stretch;
  background-color: var(--color-gray-200);
  padding: var(--padding-81xl) 0 0;
  box-sizing: border-box;
  gap: 80px;
  max-width: 100%;
  z-index: 1;
}
.onboarding {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  overflow: hidden;
  line-height: normal;
  letter-spacing: normal;
}
@media screen and (max-width: 1200px) {
  .rectangleParent {
    padding-top: 65px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 1050px) {
  .useOfStablesail {
    font-size: var(--font-size-27xl);
  }
  .loremIpsumDolorContainer,
  .watchOrSkimContainer {
    font-size: var(--font-size-7xl);
    line-height: 40px;
  }
  .watchOrSkimContainer {
    line-height: 43px;
  }
}
@media screen and (max-width: 750px) {
  .rectangleGroup {
    gap: var(--gap-xl);
  }
  .rectangleParent {
    gap: var(--gap-21xl);
    padding-top: var(--padding-23xl);
    box-sizing: border-box;
  }
}

@media screen and (min-width: 1200px) {
  
  .rectangleParent {
    padding-top: 40px;
  }
}


@media screen and (max-width: 450px) {
  .useOfStablesail {
    font-size: var(--font-size-16xl);
  }
  .loremIpsumDolorContainer,
  .watchOrSkimContainer {
    font-size: var(--font-size-lgi);
    line-height: 30px;
  }
  .watchOrSkimContainer {
    line-height: 32px;
  }
  .rectangleParent {
    gap: var(--gap-xl);
  }
}
