.dashboard {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
  text-shadow: 0 9px 17px rgba(0, 0, 0, 0.42);
  z-index: 2;
}
.featureCard {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl) var(--padding-7xs) var(--padding-2xl);
}
.group10000062531 {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  z-index: 2;
}
.onboardingTutorial {
  position: relative;
  line-height: 150%;
  font-weight: 600;
}
.featureDescription,
.onboardingTutorialWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.onboardingTutorialWrapper {
  border-radius: var(--br-5xs);
  background-color: var(--color-cadetblue-100);
  overflow: hidden;
  justify-content: flex-start;
  padding: var(--padding-base) var(--padding-31xl);
  white-space: nowrap;
  z-index: 2;
}
.featureDescription {
  align-self: stretch;
  justify-content: center;
  padding: 0 var(--padding-xl);
  font-size: var(--font-size-base);
  font-family: var(--font-inter);
}
.features,
.featuresContent {
  align-self: stretch;
  display: flex;
  max-width: 100%;
}
.featuresContent {
  width: 1114px;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.features {
  height: 790px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--font-size-39xl);
  color: var(--color-white);
  font-family: var(--font-nunito);
}
@media screen and (max-width: 1050px) {
  .dashboard {
    font-size: var(--font-size-27xl);
  }
}
@media screen and (max-width: 450px) {
  .dashboard {
    font-size: var(--font-size-16xl);
  }
  .onboardingTutorialWrapper {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
}
