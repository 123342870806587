.headerGraphics,
.icon {
  position: absolute;
  top: 0;
  left: 0;
}
.icon {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: none;
}
.headerGraphics {
  background-color: var(--color-gray);
  width: 1440px;
  height: 1024px;
  z-index: 1;
}
.parent {
  width: 100%;
  height: 1299px;
  position: absolute;
  margin: 0 !important;
  right: 0;
  bottom: -275px;
  left: 0;
  background-image: url(/public/1-5@2x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.frameChild {
  width: 1240px;
  height: 670px;
  position: relative;
  border-radius: var(--br-mini);
  background-color: var(--color-darkslategray);
  border: 1px solid var(--color-cadetblue-200);
  box-sizing: border-box;
  display: none;
  max-width: 100%;
}
.tag {
  width: 82px;
  z-index: 1;
}
.p {
  margin: 0;
}
.symbol {
  color: #3af8fa;
  white-space: pre-wrap;
}
.p1,
.symbol {
  margin: 0;
}
.alphausdtusdt,
.apeusdtusdt,
.galausdtusdt,
.injusdtusdt,
.opusdtusdt,
.pepeusdtusdt {
  margin: 0;
  white-space: pre-wrap;
}
.tagValue {
  height: 270px;
  position: relative;
  line-height: 150%;
  display: inline-block;
  z-index: 1;
}
.rectangleParent,
.tagParent {
  flex-direction: column;
  justify-content: flex-start;
}
.tagParent {
  display: flex;
  align-items: flex-start;
  gap: 40px;
}
.rectangleParent {
  width: 1240px;
  border-radius: var(--br-mini);
  background-color: var(--color-gray-200);
  border: 1px solid var(--color-cadetblue-200);
  padding: 58px 39px var(--padding-19xl);
  gap: 100px;
  max-width: 100%;
  z-index: 2;
}
.dashboard,
.mainContent,
.rectangleParent {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
}
.mainContent {
  
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-end;
  /* padding: 0 var(--padding-xl) 0 0; */
  max-width: 100%;
}
.dashboard {
  width: 100%;
  height: 1024px;
  position: relative;
  background-color: var(--color-white);
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0 155px;
  gap: 99px;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  font-size: 18.1px;
  color: var(--color-gainsboro-200);
  font-family: var(--font-courier-prime);
}

.table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

th {
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}

th:first-child {
  border-left: 2px solid black;
}

th:last-child {
  border-right: 2px solid black;
}

td:first-child {
  border-left: 2px solid black;
}

td:last-child {
  border-right: 2px solid black;
}

@media screen and (max-width: 1050px) {
  .dashboard {
    height: auto;
    min-height: 1024;
  }
}
@media screen and (max-width: 725px) {
  .tagParent {
    gap: var(--gap-xl);
  }
  .rectangleParent {
    padding-top: var(--padding-19xl);
    padding-bottom: var(--padding-6xl);
    box-sizing: border-box;
  }
  .dashboard,
  .rectangleParent {
    gap: var(--gap-31xl);
  }
}
@media screen and (max-width: 450px) {
  .dashboard,
  .rectangleParent {
    gap: var(--gap-6xl);
  }
}
