.accountBalance,
.dataValues {
  align-self: stretch;
  position: relative;
  line-height: 150%;
  font-weight: 600;
}
.dataValues {
  font-size: var(--font-size-21xl);
  letter-spacing: -0.02em;
  line-height: 110%;
  white-space: nowrap;
}
.card,
.cards {
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.card {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.04);
  border-radius: var(--br-5xs);
  background-color: var(--color-steelblue);
  border: 1px solid var(--color-gainsboro-100);
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-3xl) var(--padding-xl) var(--padding-3xl)
    var(--padding-4xl);
  gap: var(--gap-base);
}
.cards {
  flex-direction: row;
  min-width: 273px;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-white);
  font-family: var(--font-nunito);
}
@media screen and (max-width: 1050px) {
  .dataValues {
    font-size: var(--font-size-13xl);
    line-height: 35px;
  }
}
@media screen and (max-width: 1000px) {
  .dataValues {
    font-size: var(--font-size-13xl);
    line-height: 35px;
  }
}
@media screen and (max-width: 450px) {
  .dataValues {
    font-size: var(--font-size-5xl);
    line-height: 26px;
  }
  .cards {
    gap: var(--gap-base);
  }
}
