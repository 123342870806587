.borderless {
    border: none !important;
    padding: 6px;
    /* vertical-align: middle; */
    
}
td:nth-child(3), td:last-child {
    
    padding-top: 2px; 
    padding-left: 8px;
  }