.pageContentIcon {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.frameChild {
  align-self: stretch;
  height: 1852px;
  position: relative;
  background-color: var(--color-gray-200);
  display: none;
}
.home,
.rectangleParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.rectangleParent {
  align-self: stretch;
  background-color: var(--color-gray-200);
  padding: 150px 0 0;
  box-sizing: border-box;
  gap: 100px;
  max-width: 100%;
  z-index: 1;
}
.home {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  overflow: hidden;
  line-height: normal;
  letter-spacing: normal;
}
@media screen and (max-width: 1200px) {
  .rectangleParent {
    padding-top: 97px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 1050px) {
  .rectangleParent {
    padding-top: 63px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 750px) {
  .rectangleParent {
    gap: 50px;
  }
}
@media screen and (max-width: 450px) {
  .rectangleParent {
    gap: var(--gap-6xl);
    padding-top: 41px;
    box-sizing: border-box;
  }
}
