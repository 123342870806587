.resized-image {
    width: 100%;
    /* Adjust width as needed */
    height: auto;
    /* Maintain aspect ratio */
    max-width: 600px;
    /* Maximum width of the image */
    border: 1px solid #ccc;
    /* Optional border */
    border-radius: 8px;
    /* Optional rounded corners */
    display: block;
    /* Ensures image is displayed as a block element */
    margin: 0 auto;
    /* Centers the image horizontally */
}

.custom-header {
    font-size: 2.5rem;
    font-weight: bold;
    color: #2aff1e;
}

.custom-subheader {
    font-size: 2rem;
    font-weight: semi-bold;
    color: #ffffff;
}

.custom-subsubheader {
    font-size: 1.5rem;
    font-weight: semi-bold;
    color: #ffffff;
}

.blog-content {
    font-size: 1.5rem;
    line-height: 1.5;
    color: #ffffff;
}