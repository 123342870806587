@media screen and (max-width: 750px) {
.resHeading {
    font-size: var(--font-size-13xl) !important;
    line-height: 40px !important;
    
}

.resPadTop {
    padding-top: 0px !important;
}

.resText {
    font-size: var(--font-size-7xl) !important;
}

.width150 {
    width: 150px;
}

.borderWhite {
    border: 1px solid white;
}


}

@media screen and (max-width: 1199px) {
    .displayNone {
        display: none !important; 
    }
}

